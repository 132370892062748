/* globals debug idb */

/**
 * simple indexeddb wrapper that represents a single main store and a meta store
 * based on: https://developers.google.com/web/ilt/pwa/working-with-indexeddb
 */

var global = this;

(function () {
  var dbg = debug('zc:idbStore')
  var IdbStore = (function () {
    'use strict'

    var IdbStore = function (config) {
      this.name = config.name || this.constructor.name
      this.dbName = config.dbName
      this.version = config.version || 1
      this.stores = config.stores
      this.storeName = this.stores[0].name // store 0 is the main store
      this.metaStoreName = this.stores[1].name
      this.metaDefaults = config.metaDefaults
      this.connectionOpen = false
    }

    IdbStore.prototype.open = function () {
      var self = this

      // if open has already been called, returning the promise will
      // distribute the open db object
      if (this.dbOpenPromise) return this.dbOpenPromise

      dbg('Opening idb database %s version %d', this.dbName, this.version)

      self.dbOpenPromise = idb.openDb(self.dbName, self.version, function (upgradeDb) {
        dbg('Upgrade idb database %s version %d', self.dbName, self.version)
        self.stores.forEach(function (store) {
          if (!upgradeDb.objectStoreNames.contains(store.name)) {
            dbg('creating object store %s with options %o: ', store.name, store.options)
            upgradeDb.createObjectStore(store.name, store.options)
            store.indexes.forEach(function (index) {
              dbg('creating index %s with options %o: ', index.name, index.options)
              store.createIndex(index.name, index.name, index.options)
            })
          }
        })
      }).then(function (db) {
        dbg('Opened idb database %s version %d', self.dbName, self.version)
        self.connectionOpen = true
        return db
      })

      return self.dbOpenPromise
    }

    IdbStore.prototype.close = function () {
      var self = this
      if (!this.dbOpenPromise) return // already closed
      return this.dbOpenPromise.then(function (db) {
        dbg('close')
        self.dbOpenPromise = undefined
        self.connectionOpen = false
        return db.close()
      })
    }

    IdbStore.prototype.add = function (item) {
      var self = this
      if (dbg.enabled) this.now = performance.now()
      if (dbg.enabled && this.then) dbg('Add time delta: %d', this.now - this.then); this.then = this.now
      return self.open().then(function (db) {
        dbg('add')
        var tx = db.transaction(self.storeName, 'readwrite')
        var store = tx.objectStore(self.storeName)
        dbg('Adding item: ', item)
        store.add(item)
        tx.commit && tx.commit()
        return tx.complete
      })
    }

    // IdbStore.prototype.addWithMeta = function (item, getNewMeta) {
    //   var self = this
    //   if (dbg.enabled) this.now = performance.now()
    //   if (dbg.enabled && this.then) dbg('Add time delta: %d', this.now - this.then); this.then = this.now
    //   var tx
    //   return self.open().then(function (db) {
    //     tx = db.transaction([self.storeName, self.metaStoreName], 'readwrite')
    //     var store = tx.objectStore(self.storeName)
    //     var metaStore = tx.objectStore(self.metaStoreName)
    //     store.add(item)
    //     return metaStore.openCursor()
    //   }).then(function (metaCursor) {
    //     if (!metaCursor) return
    //     return metaCursor.update(getNewMeta(item, metaCursor.value))
    //     // return metaCursor.continue().then(iterateCursor)
    //   }).then(function () {
    //     return tx.complete
    //   }).then(function () {
    //     return item
    //   })
    // }

    IdbStore.prototype.put = function (item, key, storeName) {
      var self = this
      storeName = storeName || this.storeName
      return self.open().then(function (db) {
        dbg('put')
        var tx = db.transaction(storeName, 'readwrite')
        var store = tx.objectStore(storeName)
        store.put(item, key)
        tx.commit && tx.commit() // explicitly commit if that feature is present
        return tx.complete
      })
    }

    IdbStore.prototype.get = function (key, storeName) {
      var self = this
      storeName = storeName || this.storeName
      return self.open().then(function (db) {
        dbg('get')
        var tx = db.transaction(storeName, 'readonly')
        var store = tx.objectStore(storeName)
        return store.get(key)
      })
    }

    IdbStore.prototype.getAll = function (storeName) {
      storeName = storeName || this.storeName
      return this.open().then(function (db) {
        dbg('getAll')
        var tx = db.transaction(storeName, 'readonly')
        var store = tx.objectStore(storeName)
        return store.getAll()
      })
    }

    IdbStore.prototype.getAllUsingCursor = function (storeName) {
      storeName = storeName || this.storeName
      var data = []
      return this.open().then(function (db) {
        dbg('getAllUsingCursor')
        var tx = db.transaction(storeName, 'readonly')
        var store = tx.objectStore(storeName)
        store.iterateCursor(function (cursor) {
          // when this is undefined then we've reached the end
          if (!cursor) return
          data.push(cursor.value)
          cursor.continue()
        })

        return tx.complete.then(function () {
          return data
        })
      })
    }

    IdbStore.prototype.getLast = function (storeName) {
      var self = this
      storeName = storeName || this.storeName
      return self.open().then(function (db) {
        dbg('getLast')
        var tx = db.transaction(storeName, 'readonly')
        var store = tx.objectStore(storeName)
        return store.openCursor(null, 'prev')
      }).then(function (cursor) {
        return cursor ? cursor.value : undefined
      })
    }

    IdbStore.prototype.initDbMeta = function () {
      var self = this
      dbg('initDbMeta')
      var defaults = Object.assign({}, self.metaDefaults)
      return self.put(defaults, 1, self.metaStoreName).then(function () {
        return defaults
      })
    }

    IdbStore.prototype.getDbMeta = function () {
      var self = this
      dbg('getDbMeta')
      return self.get(1, self.metaStoreName).then(function (meta) {
        if (meta) { return meta }

        // initialize the meta table with default data if it doesn't already
        return self.initDbMeta()
      })
    }

    IdbStore.prototype.updateDbMeta = function (item, metaCreator) {
      var self = this
      dbg('updateDbMeta')
      return self.getDbMeta().then(function (dbMeta) {
        dbMeta = dbMeta || self.metaDefaults // start with defaults is no dbMeta already exists
        var newDbMeta = metaCreator(item, dbMeta)
        return self.put(newDbMeta, 1, self.metaStoreName).then(function () {
          return newDbMeta
        })
      })
    }

    IdbStore.prototype.delete = function (key) {
      var self = this
      return self.open().then(function (db) {
        dbg('delete')
        var tx = db.transaction(self.storeName, 'readwrite')
        var store = tx.objectStore(self.storeName)
        store.delete(key)
        return tx.complete
      })
    }

    IdbStore.prototype.clear = function (storeName) {
      var self = this
      storeName = storeName || this.storeName
      return self.open().then(function (db) {
        dbg('clear')
        var tx = db.transaction(storeName, 'readwrite')
        var store = tx.objectStore(storeName)
        store.clear()
        return tx.complete
      })
    }

    IdbStore.prototype.deleteDatabase = function () {
      var self = this
      dbg('deleteDatabase')
      return this.clear(self.storeName).then(function () {
        return self.clear(self.metaStoreName)
      })
    }

    IdbStore.prototype.checkDbExists = function () {
      dbg('checkDbExists')
      // it doesn't really matter if it existed
      // once you open to check, it now does exist
      // if store is empty then it might as well have
      // never existed
      return this.getLast().then(function (lastItem) {
        return !!lastItem
      })
    }

    IdbStore.prototype.count = function () {
      dbg('count')
      return self.open().then(function (db) {
        var tx = db.transaction(self.storeName, 'readonly')
        var store = tx.objectStore(self.storeName)
        return store.count()
      })
    }

    return IdbStore
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = IdbStore
  else global.IdbStore = IdbStore
})()
