/* globals streamify debug MemoryStore */

/**
 * Stream node version of MemoryStore
 * stores incoming audio chunks consecutively using the FileSystem api
 */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:memoryStoreStreamNode')

  var MemoryStoreStreamNode = (function () {
    var MemoryStoreStreamNode = function (config) {
      MemoryStore.call(this, config)
      dbg.enabled = config.debug
      dbg('Initialized with config: ', config)

      this.messagePort = config.messagePort
      this.uploadPort = config.uploadPort

      if (this.messagePort) this.initPortListeners()

      this.on('incomingChunk', this.processChunk)

      dbg('init complete')
      dbg('this.messagePort instanceof MessagePort ===', this.messagePort instanceof MessagePort)
    }

    // must be first after constructor
    MemoryStoreStreamNode.prototype = Object.create(MemoryStore.prototype)

    MemoryStoreStreamNode.prototype.initPortListeners = function (e) {
      var self = this
      var port = this.messagePort
      port.onmessage = (e) => {
        switch (e.data.command) {
          case 'export':
            self.export().then(function (data) {
              port.postMessage({id: e.data.id, result: data})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'getBytesSaved':
            self.getBytesSaved().then(function (bytes) {
              port.postMessage({id: e.data.id, result: bytes})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'clear':
            self.clear().then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
        }
      }

      port.start()

      this.on('change:bytesRecorded', function (bytesRecorded) {
        port.postMessage({command: 'change:bytesRecorded', bytesRecorded: bytesRecorded})
      })

      this.on('localbackup:failedWrite', function () {
        port.postMessage({command: 'localbackup:failedWrite'})
      })
    }

    MemoryStoreStreamNode.prototype.processChunk = function (chunk, isLast) {
      if (chunk) {
        dbg(this.format, ':processChunk - bytes: ', chunk.size, ' isLast: ', isLast)
        // clone chunk and store it
        this.addChunk(chunk, isLast)
      }

      this.trigger('outgoingChunk', chunk, isLast)

      if (this.uploadPort) {
        this.uploadPort.postMessage({
          command: 'data',
          blob: chunk,
          isLast: isLast
        })
      }
    }

    streamify.mixin(MemoryStoreStreamNode.prototype)

    return MemoryStoreStreamNode
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = MemoryStoreStreamNode
  else global.MemoryStoreStreamNode = MemoryStoreStreamNode
})()
