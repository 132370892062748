var global = this;

(function () {
  global.utils = global.utils || {}

  global.utils.audio = {
    diffArrays: function (a, b) {
      var diffIndexes = []
      var longer = a.length >= b.length ? a : b
      var shorter = a.length < b.length ? a : b
      longer.forEach(function (chunk, i) {
        if (chunk !== shorter[i]) {
          diffIndexes.push(i)
        }
      })

      return diffIndexes
    },

    writeString: function (view, offset, string) {
      for (var i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
      }
    },

    createWavHeader: function (numSamples, sampleRate) {
      sampleRate = sampleRate || 44100
      var numChannels = 1
      var buffer = new ArrayBuffer(44)// + samples.length * 2);
      var view = new DataView(buffer)

      /* RIFF identifier */
      this.writeString(view, 0, 'RIFF')
      /* RIFF chunk length */
      view.setUint32(4, 36 + numSamples * 2, true)
      /* RIFF type */
      this.writeString(view, 8, 'WAVE')
      /* format chunk identifier */
      this.writeString(view, 12, 'fmt ')
      /* format chunk length */
      view.setUint32(16, 16, true)
      /* sample format (raw) */
      view.setUint16(20, 1, true)
      /* channel count */
      view.setUint16(22, numChannels, true)
      /* sample rate */
      view.setUint32(24, sampleRate, true)
      /* byte rate (sample rate * block align) */
      view.setUint32(28, sampleRate * 2 * numChannels, true)
      /* block align (channel count * bytes per sample) */
      view.setUint16(32, numChannels * 2, true)
      /* bits per sample */
      view.setUint16(34, 16, true)
      /* data chunk identifier */
      this.writeString(view, 36, 'data')
      /* data chunk length */
      view.setUint32(40, numSamples * 2, true)

      // utils.floatTo16BitPCM(view, 44, samples);

      return view
    },

    writeWavHeader: function (pcm16Blob, sampleRate) {
      var numSamples = pcm16Blob.size / 2
      var wavHeader = this.createWavHeader(numSamples, sampleRate)
      var wavBlob = new Blob([wavHeader.buffer, pcm16Blob])
      return wavBlob
    },

    interleave: function (inputL, inputR) {
      var length = inputL.length + inputR.length
      var result = new Float32Array(length)

      var index = 0
      var inputIndex = 0

      while (index < length) {
        result[index++] = inputL[inputIndex]
        result[index++] = inputR[inputIndex]
        inputIndex++
      }
      return result
    },

    pcmBlobToWavBlob: function (pcmBlob) {
      var numSamples = pcmBlob.size / 2 // assuming 16bit pcm
      var wavHeader = this.createWavHeader(numSamples, 48000)
      var wavHeaderBlob = new Blob([wavHeader.buffer])
      var wavBlob = new Blob([wavHeaderBlob, pcmBlob], {type: 'audio/wav'})
      return wavBlob
    },

    /**
     * Returns a constructor for the given typed array
     * @param  {typedArray} typedArray Int16/Float32/Uint8/etc...
     * @return {Constructor}           Constructor for given array
     */
    getTypedArrayConstructor: function (typedArray) {
      var typeString = Object.prototype.toString.call(typedArray).match(/\[object (.*)\]/)[1]
      var constructor = global[typeString]
      if (!constructor) throw new Error('unable to infer type of' + typedArray)
      return constructor
    },

    /**
     * Remove zeros from the end of array or typed array
     * @param  {Array} array Array or typed array
     * @return {Array}       Same type as input
     */
    trimTrailingZeros: function (array) {
      // // get the proper constructor for the array;
      // var constructor = utils.getTypedArrayConstructor(typedArray);
      var i
      var zeroStartIndex = array.length - 1

      // reverse through array checking for beginning of continuous zeros
      for (i = zeroStartIndex; i >= 0; i--) {
        if (array[i] !== 0) {
          zeroStartIndex = i + 1
          break
        }
      }

      // create new typed array of size of original array minus zeros
      var trimmedArray = array.slice(0, zeroStartIndex)
      return trimmedArray
    },

    /**
     * Merge an array of typed arrays into a single array of the same type
     * @param  {Array}        arrays       Array of typed arrays
     * @param  {Int}          totalLength  Length of output array
     * @param  {Constructor}  constructor  Optional constructor of output array
     * @return {TypedArray}                Merged typed array
     */
    mergeTypedArrays: function (arrays, totalLength, constructor) {
      if (!constructor) {
        // if no constructor is provided then infer the type from the first item in the array
        constructor = this.getTypedArrayConstructor(arrays[0])
      }

      var result = new constructor(totalLength)

      var offset = 0

      for (var i = 0; i < arrays.length; i++) {
        result.set(arrays[i], offset)
        offset += arrays[i].length
      }

      return result
    },

    mergeArrayBuffers: function (arrayBuffers, totalLength) {
      var merged = new Uint8Array(totalLength)
      var offset = 0
      for (var i = 0; i < arrayBuffers.length; i++) {
        merged.set(new Uint8Array(arrayBuffers[i]), offset)
        offset += arrayBuffers[i].byteLength
      }
      return merged.buffer
    },

    // check endian architecture
    isLittleEndian: function () {
      var ab = new ArrayBuffer(2)
      var u8Array = new Uint8Array(ab)
      var u16Array = new Uint16Array(ab)
      u8Array[0] = 0xAA
      u8Array[1] = 0xBB
      return u16Array[0] === 0xBBAA
    },

    convertFloat32toInt16: function (f32, isLittleEndian) {
      /**
       * Convert the given Float32Array to a 16bit PCM.
       * PCM is usually little endian and this function
       * is specialized for little endian CPUs (vast majority).
       * It avoids the need to switch the byte order.
       * Returns an Int16Array.
       */
      function toPCM16LE (f32Array) {
        var length = f32Array.length
        var i16Array = new Int16Array(length)

        for (var i = 0; i < length; i++) {
          var value = f32Array[i]
          if (value > 1) {
            i16Array[i] = 0x7FFF
          } else if (value < -1) {
            i16Array[i] = -0x8000
          } else if (value < 0) {
            i16Array[i] = value * 0x8000
          } else {
            i16Array[i] = value * 0x7FFF
          }
        }

        return i16Array
      }

      /**
       * Convert the given Float32Array to a 16bit PCM,
       * specialized for (rare) big endian architectures.
       * Returns an Int16Array.
       */
      function toPCM16BE (f32Array) {
        var length = f32Array.length
        var i16Array = new Int16Array(length)

        for (var i = 0; i < length; i++) {
          var value = f32Array[i]
          if (value > 1) {
            value = 0x7FFF
            i16Array[i] = (value << 8) | ((value >> 8) & 0xFF)
          } else if (value < -1) {
            value = -0x8000
            i16Array[i] = (value << 8) | ((value >> 8) & 0xFF)
          } else if (value < 0) {
            value = value * 0x8000
            i16Array[i] = (value << 8) | ((value >> 8) & 0xFF)
          } else {
            value = value * 0x7FFF
            i16Array[i] = (value << 8) | ((value >> 8) & 0xFF)
          }
        }

        return i16Array
      }

      var toPCM16 = isLittleEndian ? toPCM16LE : toPCM16BE

      return toPCM16(f32)
    },

    drawBuffer: function (canvas, buffer) {
      var isAudioBuffer = !!buffer.sampleRate
      var data = isAudioBuffer ? buffer.getChannelData(0) : buffer
      var width = canvas.width
      var height = canvas.height
      var context = canvas.getContext('2d')
      var step = Math.ceil(data.length / width)
      var amp = height / 2

      context.clearRect(0, 0, width, height)

      for (var i = 0; i < width; i++) {
        var min = 1.0
        var max = -1.0
        for (var j = 0; j < step; j++) {
          var datum = data[(i * step) + j]
          if (datum < min) min = datum
          if (datum > max) max = datum
        }
        context.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp))
      }
    },

    /**
     * used to load an external file as an instance of ArrayBuffer
     * @param  {String}         url
     */
    fetchArrayBuffer: function (url) {
      return new Promise(function (resolve, reject) {
        var request = new XMLHttpRequest()
        request.open('GET', url, true)
        request.responseType = 'arraybuffer'
        request.onreadystatechange = function () {
          if (this.readyState === XMLHttpRequest.DONE) {
            if (this.status === 200) {
              resolve(request.response)
            } else {
              reject(new Error(this.statusText))
            }
          }
        }

        try {
          request.send()
        } catch (e) {
          reject(e)
        }
      })
    },

    fetchArrayBuffers: function (urls) {
      var self = this
      var promises = urls.map(function (url) {
        return self.fetchArrayBuffer(url)
      })
      return Promise.all(promises)
    },

    /**
     * used to load an audio file as an instance of AudioBuffer
     * @param  {AudioContext}   actx
     * @param  {String}         url
     */
    fetchAudioBuffer: function (actx, url) {
      var self = this
      return new Promise(function (resolve, reject) {
        self.fetchArrayBuffer(url).then(function (arrayBuffer) {
          actx.decodeAudioData(arrayBuffer, function (audioBuffer) {
            resolve(audioBuffer)
          }, reject)
        }).catch(reject)
      })
    },

    isAudioBuffer: function (input) {
      return !!input.sampleRate && !!input.getChannelData
    },

    createAudioBuffer: function (channelCount, f32Channels, actx) {
      var numFrames = f32Channels[0].length
      var audioBuffer = actx.createBuffer(channelCount, numFrames, actx.sampleRate)
      for (var channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        var channelBuffer = audioBuffer.getChannelData(channel)
        for (var i = 0; i < channelBuffer.length; i++) {
          channelBuffer[i] = f32Channels[channel][i]
        }
      }
      return audioBuffer
    },

    playAudioBuffer: function (audioBuffer, actx) {
      actx = actx || new AudioContext()
      var source = actx.createBufferSource()
      source.buffer = audioBuffer
      source.connect(actx.destination)
      source.start(0)
    },

    arrayBufferToFloat32Array: function (arrayBuffer, offset) {
      offset = offset || 0 // offset is useful in this case if you want to strip wav headers

      // Float32Array byteLength must be a multiple of 4
      // trim any remainder when instantiating
      var remainder = arrayBuffer.byteLength % 4
      var length = (arrayBuffer.byteLength - (offset + remainder)) / 4
      return new Float32Array(arrayBuffer, offset, length)
    },

    checkForSampleRateMismatch: function (incumbentSampleRate) {
      var result
      var freshActx = new AudioContext()

      if (freshActx.sampleRate !== incumbentSampleRate) {
        // Oh No!!! log the error so it gets sent to the bug tracker
        // If this ends up being a widespread problem, we should notify the host to stop the recording
        console.error('Sample rate mismatch detected.  Incumbent sample rate: ', incumbentSampleRate, 'Fresh sample rate: ', freshActx.sampleRate)
        result = {mismatch: true, freshSampleRate: freshActx.sampleRate}
      } else {
        result = {mismatch: false, freshSampleRate: freshActx.sampleRate}
      }

      // clean up the temp audio context
      freshActx.close()

      return result
    },

    rootMeanSquare: function (values) {
      var sum = values.reduce(function (sum, val) {
        return sum + Math.pow(val, 2)
      }, 0)
      var mean = sum / values.length
      return mean
    }
  }
})()
