/* globals debug events */
var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:streamify')

  // call on an object to give it the ability to pipe chunks to evented targets
  var streamify = {
    mixin: function (obj) {
      return Object.assign(obj, events.mixin({

        pipe: function (target) {
          dbg('Setting pipe from ' + this.name + ' to ' + target.name)
          // check that target is evented
          if (!target || typeof target.trigger !== 'function') throw new Error('Pipe can only be called on evented targets')

          // using Map since we can set objects as keys
          this._pipes = this._pipes || new Map()

          // create callback to register that triggers desired event on target
          var callback = function () {
            var args = Array.prototype.slice.call(arguments)
            args.unshift('incomingChunk')
            target.trigger.apply(target, args)
          }

          this.on('outgoingChunk', callback)

          this._pipes.set(target, callback)
        },

        disconnect: function (target) {
          this.off('outgoingChunk', this._pipes.get(target))
          this._pipes.delete(target)
        },

        disconnectAll: function () {
          var self = this
          this._pipes.forEach(function (callback, target) {
            self.off('outgoingChunk', callback)
            self._pipes.delete(target)
          })
        }
      }))
    },

    logPipeline: function (tree, indent) {
      indent = indent || 0

      function walk (tree, trailIndexes) {
        trailIndexes = trailIndexes || []
        tree.forEach(function (node) {
          var isLast = tree.indexOf(node) === tree.length - 1
          var row = new Array(indent).fill(' ')

          trailIndexes.forEach(function (idx) {
            row[idx] = '\''
          })

          console.log(row.join('') + (indent ? '`-' : ' -'), node.name)
          if (node._pipes && node._pipes.size) {
            if (!isLast) trailIndexes.push(indent)
            indent += 3
            walk(Array.from(node._pipes.keys()), trailIndexes)
          }
          if (isLast) {
            if (trailIndexes.indexOf(indent) > -1) trailIndexes.splice(indent, 0)
            indent -= 3
          }
        })
      }

      walk(tree)
    }
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = streamify
  else global.streamify = streamify
})()
