/* globals debug */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:events')

  // call on an object to give it the ability to pipe chunks to consumers
  // the object must implement push and emit for this to work
  var events = {
    mixin: function (obj) {
      return Object.assign(obj, {
        on: function (eventStr, fct) {
          dbg('on(' + eventStr + ', ' + fct + ')')
          if (!eventStr || typeof eventStr !== 'string') throw new Error('You must pass in a valid event name string as the first argument')
          if (typeof fct !== 'function') throw new Error('You must pass in a function as the second argument')
          if (this._events_cleanup) {
            dbg('Trying to subscribe after cleanup, ignoring')
            return
          }
          this._events = this._events || {}
          var events = eventStr.split(' ')
          for (var i = 0; i < events.length; i++) {
            var event = events[i]
            this._events[event] = this._events[event] || []
            this._events[event].push(fct)
          }
        },

        once: function (eventStr, fct) {
          dbg('once(' + eventStr + ', ' + fct + ')')
          // var self = this
          // var runOnce = function () {
          //   self.off(eventStr, runOnce)
          //   fct.apply(self, arguments)
          // }
          fct._event_ttl = 1
          this.on(eventStr, fct)
        },

        off: function (eventStr, fct) {
          dbg('off(' + eventStr + ', ' + fct + ')')
          this._events = this._events || {}
          var events = eventStr.split(' ')
          for (var i = 0; i < events.length; i++) {
            var event = events[i]
            if (event in this._events === false) return
            if (typeof fct === 'undefined') { // if no function is passed, remove all callbacks for event
              this._events[event] = []
            } else if (this._events[event].indexOf(fct) > -1) { // check if the given function is in the event's array
              this._events[event].splice(this._events[event].indexOf(fct), 1) // remove the function
            }
          }
        },

        trigger: function (event /* , args... */) {
          // dbg('trigger(' + event + ')')
          var events = this._events || {}
          if (event in events === false) return
          for (var i = 0; i < events[event].length; i++) {
            var callback = events[event][i]
            if (!callback) return
            if (callback._event_ttl) {
              callback._event_ttl--
              if (callback._event_ttl === 0) this.off(event, callback)
            }
            callback.apply(this, Array.prototype.slice.call(arguments, 1))
          }
        },

        cleanup: function () {
          this._events_cleanup = true
          this._events = null
        }
      })
    }
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = events
  else global.events = events
})()
