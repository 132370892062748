/* globals zc Backbone utils moment saveAs servars ua */

var global = this;

(function () {
  'use strict'

  global.utils = {
    arrayAbs: function (array) {
      // return highest absolute value from array

      var high = 0

      for (var i = 0; i < array.length; i++) {
        var abs = Math.abs(array[i])
        high = (abs > high) ? abs : high
      }

      return high
    },

    cloneCanvas: function (oldCanvas) {
      // create a new canvas
      var newCanvas = document.createElement('canvas')
      var context = newCanvas.getContext('2d')

      // set dimensions
      newCanvas.width = oldCanvas.width
      newCanvas.height = oldCanvas.height

      // apply the old canvas to the new one
      context.drawImage(oldCanvas, 0, 0)

      // return the new canvas
      return newCanvas
    },

    cdnUrl: function (url) {
      var formattedUrl = url && url.charAt(0) === '/' ? url.substr(1) : url
      if (servars.bundleMedia && servars.versionManifest) {
        if (servars.versionManifest[formattedUrl]) {
          return servars.cdnBaseUrl + '/' + servars.versionManifest[formattedUrl]
        }
      }
      return url
    },

    // this needs to match the server-side utils.slugify function
    slugify: function (s) {
      var slugifyStripRe = /[^\w\s-]/g
      var slugifyHyphenateRe = /[-\s]+/g
      s = s.replace(slugifyStripRe, '').trim().toLowerCase()
      s = s.replace(slugifyHyphenateRe, '-')
      return s
    },

    /**
     * Removes characters that are not valid in the username
     */
    sanitizeUserName: function (username) {
      return username.replace(/[^A-Za-z0-9_ ]/g, '_')
    },

    /**
     * Replaces all accented characters with their non-accented counterparts, e.g. é -> e
     * @param {string} str
     */
    deAccentify: function (str) {
      // normalize('NFD') returns a copy of string in its canonical form, basically that means that characters like é are decomposed in e + ', which means we can then throw the accents out by replacing them with '' (throwing away anything that is not in the latin character range)
      // latin characters are in the range 0020..007f
      // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
      // https://jrgraphix.net/r/Unicode
      return str.normalize('NFD').replace(/[^\u0020-\u007f]/g, '')
    },
    /**
     * Used to validate the nicknamed choosed by a guest
     * @param  {String} string The nichname
     * @return {Boolean}
     */
    validateNickname: function (string) {
      // Nickname must be less than 20 characters in length and only contain characters a-z, 0-9, and _
      var loginRegex = new RegExp(/^[A-Za-z0-9_ ]{1,20}$/)
      return loginRegex.test(string)
    },

    notify: function (type, message, options, title, icon) {
      options = options || {}
      var $notification = options.$el || $('.global-notification')
      var ttl = options.ttl

      if (type === 'error' || type === 'alert') {
        console.error(message)
      }

      var notification = new Backbone.Model({ type: type, message: message, title: title, icon: icon })
      var notificationView = new zc.views.NotificationView({ model: notification })

      $notification.html(notificationView.render().el)
      setTimeout(function () {
        notification.trigger('enter', notification)
      }, 20)

      if (options.audible) {
        options.audible.play(type)
      }

      if (ttl) {
        setTimeout(function () {
          notification.trigger('exit', notification)
        }, ttl)
      }

      return notificationView.$el
    },

    // convenience function that I can pass straight to .catch(notifyError)
    // and it will make a basic error notification
    notifyError: function (err) {
      utils.notify('error', err.toString())
    },

    logRethrow: function (err) {
      console.error(err)
      throw err
    },

    logRethrowCustom: function (customError) {
      return function (originalError) {
        console.error(originalError)
        throw customError
      }
    },

    destroyCircular: function (from, seen) {
      var to = Array.isArray(from) ? [] : {}

      seen.push(from)

      for (var i = 0, list = Object.keys(from); i < list.length; i += 1) {
        var key = list[i]

        var value = from[key]

        if (typeof value === 'function') {
          continue
        }

        if (!value || typeof value !== 'object') {
          to[key] = value
          continue
        }

        if (!seen.includes(from[key])) {
          to[key] = utils.destroyCircular(from[key], seen.slice())
          continue
        }

        to[key] = '[Circular]'
      }

      var commonProperties = ['name', 'message', 'stack', 'code']

      for (var i$1 = 0, list$1 = commonProperties; i$1 < list$1.length; i$1 += 1) {
        var property = list$1[i$1]

        if (typeof from[property] === 'string') {
          to[property] = from[property]
        }
      }

      return to
    },

    serializeError: function (value) {
      if (typeof value === 'object') {
        return utils.destroyCircular(value, [])
      }

      // People sometimes throw things besides Error objects…
      if (typeof value === 'function') {
        // JSON.stringify discards functions. We do too, unless a function is thrown directly.
        return ('[Function: ' + ((value.name || 'anonymous')) + ']')
      }

      return value
    },

    getQueryStringParams: function () {
      // returns object of query string parameters
      var params = {}
      var query = window.location.search.substring(1)

      if (!query) {
        return params
      }

      var pairs = query.split('&')

      for (var i = 0; i < pairs.length; i++) {
        var keyVal = pairs[i].split('=')
        // we need this check because if keyVal[1] is missing the value will be 'undefined' (as string)
        params[decodeURIComponent(keyVal[0])] = keyVal[1] ? decodeURIComponent(keyVal[1]) : null
      }

      return params
    },

    enumerateQueryStringParams: function () {
      // returns object of query string parameters
      var count = 0
      var query = window.location.search.substring(1)

      if (!query) {
        return count
      }

      var pairs = query.split('&')

      count = pairs.length

      return count
    },

    setQueryStringParam: function (keyVal) {
      // returns a query string with keyVal added
      var keyValStr = keyVal[0] + '=' + keyVal[1]
      if (!utils.enumerateQueryStringParams()) {
        return '?' + keyValStr
      } else if (!utils.getQueryStringParams()[keyVal[0]]) {
        window.location.search += '&' + keyValStr
        return window.location.search
      } else {
        return utils.replaceQueryStringParam(keyVal)
      }
    },

    replaceQueryStringParam: function (keyVal) {
      // replaces an existing param with new value
      var queryString = window.location.search
      var regexStr = keyVal[0] + '=[^&]*'
      var regex = new RegExp(regexStr)
      var match = regex.exec(queryString)[0]
      var replaced = queryString.replace(match, keyVal[0] + '=' + keyVal[1])
      return replaced
    },
    /**
     * Method used to format the duration of a track into an easy to ready format
     * @param  {Number} ms       The duration of the track
     * @param  {Bool} noSpaces   If the string should not contain spaces (usually false)
     * @return {String}          The duration of the track, eg: 0h 0m 32s
     */
    msToHms: function (ms, noSpaces) {
      // milliseconds to Hours Minutes Seconds (1h 2m 35s or 1h2m35s)
      var formatted
      var duration = moment.duration(ms)
      var hours = duration.get('hours')
      var minutes = duration.get('minutes')
      var seconds = duration.get('seconds')
      var timeElapsed = moment({ h: hours, m: minutes, s: seconds })

      // so we don't get the hours clipped off by days
      var totalHours = Math.floor(duration.asHours())

      if (noSpaces) {
        formatted = totalHours + 'h' + timeElapsed.format('m[m]s[s]')
      } else {
        formatted = totalHours + 'h' + timeElapsed.format(' m[m] s[s]')
      }

      return formatted
    },

    msToHours: function (ms) {
      // milliseconds to Hours (1.75h)
      var hours = ms / 1000 / 60 / 60
      var formatted = hours + 'h'
      return formatted
    },

    msToClock: function (ms) {
      // milliseconds to 01:02:35
      var duration = moment.duration(ms)
      var hours = duration.get('hours')
      var minutes = duration.get('minutes')
      var seconds = duration.get('seconds')
      var timeElapsed = moment({ h: hours, m: minutes, s: seconds })
      return timeElapsed.format('HH:mm:ss')
    },

    formattedDiskSpace: function (bytes) {
      var rounded = 0
      var unit = 'MB'

      if (bytes > 999999999) {
        unit = 'GB'
        rounded = Math.round(bytes / 1000 / 1000 / 10) / 100
      } else if (bytes > 999999) {
        unit = 'MB'
        rounded = Math.round(bytes / 1000 / 10) / 100
      } else if (bytes > 0) {
        unit = 'KB'
        rounded = Math.round(bytes / 10) / 100
      }

      return '' + rounded + ' ' + unit
    },

    forceDownload: function (blob, filename) {
      saveAs(blob, filename)
      // var url = (window.URL || window.webkitURL).createObjectURL(blob);
      // var link = window.document.createElement('a');
      // link.href = url;
      // link.download = filename || 'output.wav';
      // var click = document.createEvent("Event");
      // click.initEvent("click", true, true);
      // link.dispatchEvent(click);
    },

    sanitizeText: function (text) {
      /** see https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript/34064434#34064434 */
      var doc = new DOMParser().parseFromString(text, 'text/html')
      return doc.documentElement.textContent
    },

    linkify: function (text, targetBlank) {
      // http://, https://, ftp://
      var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim

      // www. sans http:// or https://
      var pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim

      // Email addresses
      var emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim

      text = text
        .replace(urlPattern, '<a href="$&">$&</a>')
        .replace(pseudoUrlPattern, '$1<a href="http://$2">$2</a>')
        .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>')

      if (targetBlank) {
        var $div = $('<div>').html(text).find('a').prop('target', '_blank')
        text = $div.length ? $div[0].outerHTML : text
      }

      return text
    },

    idbDatabaseExists: function (name) {
      return new Promise(function (resolve, reject) {
        var db = indexedDB
        var req

        try {
          // See if it exist
          req = db.webkitGetDatabaseNames()
          req.onsuccess = function (evt) {
            ~([].slice.call(evt.target.result)).indexOf(name)
              ? resolve(true)
              : reject(new Error('Does not exist'))
          }
        } catch (e) {
          // Try if it exist
          req = db.open(name)
          req.onsuccess = function () {
            req.result.close()
            resolve(true)
          }
          req.onupgradeneeded = function (evt) {
            evt.target.transaction.abort()
            reject(new Error('Does not exist'))
          }
        }
      })
    },

    deleteIdbDatabase: function (dbName) {
      return new Promise(function (resolve, reject) {
        var req = indexedDB.deleteDatabase(dbName)
        req.onsuccess = function () {
          resolve(dbName)
        }
        req.onerror = function (err) {
          reject(new Error('Couldn\'t delete database: ' + dbName + ' ' + err))
        }
        req.onblocked = function () {
          reject(new Error('blocked'))
        }
      })
    },

    queryStorageQuota: function () {
      return new Promise(function (resolve, reject) {
        if (navigator.storage && navigator.storage.estimate) {
          navigator.storage.estimate().then(function (data) {
            resolve({ total: data.quota, used: data.usage, free: data.quota - data.usage })
          }).catch(function () {
            resolve({ total: -1, used: -1, free: -1 })
          })
        } else if (navigator.webkitTemporaryStorage) {
          navigator.webkitTemporaryStorage.queryUsageAndQuota(function (used, total) {
            resolve({ total: total, used: used, free: total - used })
          })
        } else {
          resolve({ total: -1, used: -1, free: -1 })
        }
      })
    },

    bounceBlobToDisk: function (blob) {
      return new Promise(function (resolve, reject) {
        var dbName = 'tempDB::' + Math.round(Math.random() * 1000000)
        var objectStores = [
          { name: dbName }
        ]
        var blobStore = new zc.models.IdbStore({
          dbName: dbName,
          dbVersion: 1,
          storeName: dbName,
          stores: objectStores
        })

        blobStore.on('connected', function () {
          blobStore.add({ blob: blob }, function (err) {
            if (err) {
              console.error(err)
              reject(err)
            } else {
              blobStore.get(null, 1, function (err, obj) {
                if (err) return reject(err)
                resolve(obj.blob)
              })
            }
          })
        })
      })
    },

    getWindowOrigin: function () {
      var origin = global.location.origin
      if (!origin) {
        origin = global.location.protocol + '//' + global.location.hostname + (global.location.port ? ':' + global.location.port : '')
      }
      return origin
    },

    // executes an array of promises serially
    promiseSerial: function (funcs) {
      return funcs.reduce(function (promise, func) {
        return promise.then(function (result) {
          var output = func(result[result.length - 1])

          if (!(output instanceof Promise)) { result.push(output); return result }

          return output.then(function (currentResult) {
            result.push(currentResult); return result
          })
        })
      }, Promise.resolve([]))
    },

    consoleTable: function (s) {
      var cols = []
      for (var k in s) {
        for (var c in s[k]) {
          if (cols.indexOf(c) === -1) cols.push(c)
        }
      }

      var html = '<table><thead><tr><th></th>' +
        cols.map(function (c) { return '<th>' + c + '</th>' }).join('') +
        '</tr></thead><tbody>'
      for (var l in s) {
        html += '<tr><th>' + l + '</th>' + cols.map(function (c) { return '<td>' + (s[l][c] || '') + '</td>' }).join('') + '</tr>'
      }
      html += '</tbody></table>'

      return html
    },

    setCookie: function (keyVal) {
      document.cookie = keyVal.join('=') + '; path=/'
    },

    getCookie: function (key) {
      var result
      return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null
    },
    canUseCoupon: function (couponCode) {
      var usedCoupons = this.getCookie('usedCoupons')
      if (usedCoupons && usedCoupons.length) {
        var allUsedCoupons = usedCoupons.split(',')
        if (allUsedCoupons && allUsedCoupons.length) {
          var alreadyUsed = allUsedCoupons.find(function (c) {
            return c.toLowerCase() === couponCode.toLowerCase()
          })
          if (alreadyUsed) {
            return false
          }
        }
      }
      return true
    },
    setUsedCoupon: function (couponCode) {
      var usedCoupons = this.getCookie('usedCoupons') | ''
      usedCoupons = usedCoupons && usedCoupons.length ? usedCoupons + ',' + couponCode : couponCode
      this.setCookie(['usedCoupons', usedCoupons])
    },
    isCanvasWebglSupported: function () {
      var can = document.createElement('canvas')
      var gl

      try {
        gl = can.getContext('webgl')
      } catch (x) {
        gl = null
      }

      if (gl == null) {
        try {
          gl = can.getContext('experimental-webgl')
        } catch (x) {
          gl = null
        }
      }

      return !!gl
    },

    getVideoCardInfo: function () {
      const gl = document.createElement('canvas').getContext('webgl')
      if (!gl) {
        return {
          error: 'no webgl'
        }
      }

      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
      if (debugInfo) {
        return {
          vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
          renderer: gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
        }
      } else {
        return {
          error: 'no WEBGL_debug_renderer_info'
        }
      }
    },

    /**
     * Used to detect if the user has enabled hardware acceleration in a Chrome browser
     * we use the renderer name from webgl to detect that
     * @return {Boolean}
     */
    hasEnabledHardwareAccelaration: function () {
      var videoData = this.getVideoCardInfo()
      if (videoData.error) return false

      var match = videoData.renderer.match('Google')
      return !match
    },

    /**
     * There is an issue on Chrom 88, mac os with HW acceleration turned disabled
     * that will make the user record green
     * @return {Boolean}
     */
    hasGreenScreenIssue: function () {
      var isChrome = ['chrome', 'chromium'].indexOf(ua.browser.name.toLowerCase()) > -1
      var versionWithProblem = Number(ua.browser.version.split('.')[0]) === 88
      var isMac = ua.os.name === 'Mac OS'

      return isChrome && versionWithProblem && isMac && !this.hasEnabledHardwareAccelaration()
    },

    isMobile: function () {
      var isMobile = false
      /* eslint-disable */
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true
      }
      /* eslint-enable*/

      return isMobile
    }
  }
})()
