/* globals debug events */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:memoryStore')

  var MemoryStore = (function () {
    var MemoryStore = function (config) {
      dbg('Initialized with config: ', config)

      if (['wav', 'mp3', 'mov', 'webm', 'mp4'].indexOf(config.format) < 0) {
        throw new Error('You must initilalize with a valid audio format')
      }

      this.name = config.name || this.constructor.name
      this.format = config.format
      this.chunkSize = config.chunkSize
      this.chunks = []
      this.totalBytes = 0

      // if the track wants to use the FS and it's also available
      this.useFileSystem = !!config.useFileSystem && !!navigator.storage && !!navigator.storage.getDirectory
      this.filename = config.filename
      this.rootDir = null
      this.directoryHandle = null
      /**
       * Used to create the names of chunks.
       * start from 1 in case we'll need to create any additional header file
       * @type {Number}
       */
      this.fileIndex = 1
      this.chunkQueue = []
      this.writeInProgress = false
      if (this.useFileSystem && this.filename) {
        this.setupFileSystem()
      }
    }

    MemoryStore.prototype.setupFileSystem = async function () {
      try {
        this.rootDir = await navigator.storage.getDirectory()
      } catch (err) {
        console.error(err)
        // we had an error, fallback to memory
        this.useFileSystem = false
        return
      }

      try {
        var directoryHandle = await this._getDirectoryHandle(false)
      } catch (err) {
        // if it failed, just ignore
        // it means the folder is not present in the FS
        return
      }

      // if the folder is empty, return
      // TODO: why are we getting false positives for this?
      // if (typeof directoryHandle[Symbol.iterator] !== 'function') return

      // loop through the files in the folder
      var iter = directoryHandle.values()

      var entry = await iter.next()
      while (!entry.done) {
        var file = await entry.value.getFile()

        this.fileIndex++
        // sum all file sizes
        this.totalBytes += file.size

        entry = await iter.next()
      }

      // TODO: add for await back when eslint and gulp support it
      // for await (var entry of directoryHandle.values()) {
      // }
    }

    /**
     * Used to get a handle for a directory
     * If we successfully create it, also save a reference to it
     * @param  {Boolean} shouldCreate If we should create the folder in the process. Default: false
     * @return {Promise}
     */
    MemoryStore.prototype._getDirectoryHandle = async function (shouldCreate) {
      if (this.directoryHandle) return this.directoryHandle

      if (!this.rootDir) {
        // rootDir is set inside setupFileSystem() which is called inside the constructor
        // but that is an async function
        // in some cases, we'll call getBytesSaved() before the setupFileSystem finishes so rootDir will be null
        // TODO: this does not cover the case when there's no FS support
        this.rootDir = await navigator.storage.getDirectory()
      }

      var directoryHandle = await this.rootDir.getDirectoryHandle(this.filename, {create: !!shouldCreate})
      this.directoryHandle = directoryHandle

      return directoryHandle
    }

    /**
     * Used to create a writetable for a file inside our directory
     * @param  {String} filename The name of the file. Most likely with format 1.mp3
     * @return {Promise}
     */
    MemoryStore.prototype._getFileWriter = async function (filename) {
      // at this point, we want to create the folder if it doesn't exist
      var directoryHandle = await this._getDirectoryHandle(true)
      var fileHandle = await directoryHandle.getFileHandle(filename, {create: true})

      return fileHandle.createWritable()
    }

    /**
     * Used to write a data chunk to a file
     * We use on file one chunk, just to make sure every chunk gets written to disk
     * For this, writeables are only used once
     * @param  {Blob}  blob   The data
     * @return {Promise}
     */
    MemoryStore.prototype._writeChunkToAFile = async function (blob) {
      // get the file writer
      var filename = this.fileIndex + '.' + this.format

      try {
        var writeable = await this._getFileWriter(filename)
        await writeable.write(blob)

        writeable.close()
        this.fileIndex++
      } catch (err) {
        console.error('Failed to write to local file', err)
        this.trigger('localbackup:failedWrite')
      }
    }

    MemoryStore.prototype.processQueue = async function () {
      if (this.writeInProgress || !this.chunkQueue.length) return

      var chunk = this.chunkQueue[0].chunk

      this.writeInProgress = true

      // get a new file writer for each chunk
      await this._writeChunkToAFile(chunk)

      this.writeInProgress = false
      this.totalBytes += chunk.size
      this.chunkQueue.shift()

      this.processQueue()
    }

    MemoryStore.prototype._exportFileFromFileSystem = async function () {
      var proms = []
      var directoryHandle = await this._getDirectoryHandle()

      // loop through the files in the folder
      var iter = directoryHandle.values()

      var entry = await iter.next()
      while (!entry.done) {
        proms.push(entry.value.getFile())
        entry = await iter.next()
      }

      // if no files in folder
      if (!proms.length) {
        throw new Error('No data to export')
      }

      var files = await Promise.all(proms)

      // sort files by name to make sure the blobs are in order
      // the file name structure is: 1.mp3, 2.mp3, etc
      // this uses the number part of the name for sorting
      files.sort(function (a, b) {
        return Number(a.name.split('.')[0]) - Number(b.name.split('.')[0])
      })

      return new Blob(files)
    }

    MemoryStore.prototype.addChunk = function (chunk, isLast) {
      if (this.useFileSystem) {
        this.chunkQueue.push({chunk: chunk, isLast: isLast})
        this.processQueue()
      } else {
        this.chunks.push(chunk)
        this.totalBytes += chunk.size
      }

      this.trigger('change:bytesRecorded', this.totalBytes)
    }

    MemoryStore.prototype.export = async function () {
      if (this.useFileSystem) {
        return this._exportFileFromFileSystem()
      } else {
        if (!this.chunks.length) throw new Error('Store is empty')
        return this.chunks
      }
    }

    MemoryStore.prototype.getBytesSaved = async function () {
      if (this.totalBytes) return this.totalBytes
      if (!this.useFileSystem) {
        return 0
      }

      try {
        var directoryHandle = await this._getDirectoryHandle()
      } catch (err) {
        return 0
      }
      var iter = directoryHandle.values()

      var entry = await iter.next()
      while (!entry.done) {
        var file = await entry.value.getFile()
        this.totalBytes += file.size

        entry = await iter.next()
      }

      return this.totalBytes
    }

    /**
     * Used for cleanup
     * @return {Promise}
     */
    MemoryStore.prototype.clear = async function () {
      this.chunks = []
      this.totalBytes = 0

      await this.rootDir.removeEntry(this.filename, {recursive: true})
    }

    events.mixin(MemoryStore.prototype)

    return MemoryStore
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = MemoryStore
  else global.MemoryStore = MemoryStore
})()
